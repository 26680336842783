@font-face{
  font-family: "CustomFont";
  src: url("fonts/abeakrg.ttf");
}

h1, h2, h3 {
  font-family: "CustomFont", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.notGlobal{
	color:green;
}

:global .container{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

:global .flex{
  display: flex;
}

:global .nav-link.active, :global .nav-link{
  color: rgba(0, 0, 0, 0.5) !important;
}