.phone{
	font-weight: bold;	
}

.headerAction{
	color:black;
}

.headerAction:hover{
	text-decoration: none;
}

.lgContactInfo{
	padding-top: 1rem;
}

.headerContainer{
	max-width:100% !important;
	/*background-color:#FBEEC1;*/
}

@media (min-width: 1301){
    .headerContainer{
        height: 20vh !important;
    }
}

@media (max-width: 1300) {
    .headerContainer{
        height: 30vh !important;
    }
}

.headerImage{
	height: 15vh;
}