@media (max-width: 767){
	.linkContainer{
		text-align:right;
	}
}
@media (min-width: 768px){
	.linkContainer{
		max-height: 3rem;
		text-align:right;
	}
}

.nav{
	font-weight: bold;
	margin-top: -1.2rem;
}

.navBar{
	background-color: #BC986A;
}